<template>
  <v-container fluid id="first-screen">
    <div class="pa-4">
      <v-app-bar
        id="app-bar"
        flat
        color="transparent"
      >
        <v-toolbar-title>
          <div class="logo-container">
            <v-img
              contain
              src="@/assets/WeCook-Logo_homepage.png"
            ></v-img>
          </div>
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <div
          v-if="isUserLoggedIn" 
          class="app-bar-buttons"
        >
          <v-btn
            depressed
            color="#E7FFEF"
            class="login-btn mr-4"
            :ripple="false"
            @click="logout"
          >
            Log Out
          </v-btn>
        </div>
        
        <div
          v-else
          class="app-bar-buttons"
        >
          <v-btn
            depressed
            color="#E7FFEF"
            class="login-btn mr-1 mr-sm-4"
            :ripple="false"
            @click="showLoginForm"
          >
            Log In
          </v-btn>

          <v-btn
            depressed
            color="#5BE789"
            class="sign-up-btn d-none d-md-inline-flex"
            :ripple="false"
            @click="showRegisterForm"
          >
            Sign Up
          </v-btn>

          <v-btn
            depressed
            color="#001254"
            class="sign-up-btn d-md-none"
            :ripple="false"
            @click="showRegisterForm"
          >
            Sign Up
          </v-btn>
        </div>
      </v-app-bar>

      <div class="content-wrapper">
        <v-row class="middle-section-container">
          <v-col cos="12" md="6" class="left-col d-flex flex-column justify-center">
            <div class="first-screen-title">
              Get your delicious chef's food knocking at your door.
            </div>
            <div class="first-screen-subtitle">
              Daily meals for conscious eaters, made by a community of empowered freelance cooks
            </div>
            <div class="d-flex align-center flex-wrap">
              <div class="mr-4">
                <v-btn
                  depressed
                  class="explore-btn"
                  @click="goToMainFeed"
                >
                  Menu of the day
                </v-btn>
              </div>
              <div cols="8">
                <v-btn
                  text
                  color="#001254"
                  class="associated-chef-btn"
                  @click="becomeAssociatedChef"
                >
                  Become an associated chef
                </v-btn>
              </div>
            </div>
          </v-col>
          <v-col md="6" class="right-col d-none d-md-flex justify-end">
            <div class="mobile-left-image-container">
              <div class="mobile-left-image"></div>
            </div>
            <div class="mobile-right-image"></div>
          </v-col>
        </v-row>

        <v-row class="features-container">
          <v-col class="d-flex flex-column align-center">
            <div class="order-2 order-md-1 features-title text-center mb-md-3">Reusable packaging</div>
            <div class="order-1 order-md-2 mb-3 mb-md-0">
              <v-img
                class="features-icon"
                contain
                src="@/assets/04_wecook_web_icon.png"
              ></v-img>
            </div>
          </v-col>
          <v-col class="d-flex flex-column align-center">
            <div class="order-2 order-md-1 features-title text-center mb-md-3">Chef's made</div>
            <div class="order-1 order-md-2 mb-3 mb-md-0">
              <v-img
                class="features-icon"
                contain
                src="@/assets/07_wecook_web_icon.png"
              ></v-img>
            </div>
          </v-col>
          <v-col class="d-flex flex-column align-center">
            <div class="order-2 order-md-1 features-title text-center mb-md-3">Changing menu</div>
            <div class="order-1 order-md-2 mb-3 mb-md-0">
              <v-img
                class="features-icon"
                contain
                src="@/assets/09_wecook_web_icon.png"
              ></v-img>
            </div>
          </v-col>
          <v-col class="d-flex flex-column align-center">
            <div class="order-2 order-md-1 features-title text-center mb-md-3">Eco friendly</div>
            <div class="order-1 order-md-2 mb-3 mb-md-0">
              <v-img
                class="features-icon"
                contain
                src="@/assets/05_wecook_web_icon.png"
              ></v-img>
            </div>  
          </v-col>
        </v-row>

        <v-row class="d-md-none" @click="goToMainFeed">
          <v-col cols="12" class="text-center pa-0">
            <v-img
              contain
              src="@/assets/mobile_image_mb.svg"
            ></v-img>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" class="text-center">
            <div class="small-text font-italic">Welcome to the lite pre-beta demo of Wecook (full app release coming soon)</div>
          </v-col>
        </v-row>
      </div>

      <SignInModal
        :visible="showSignInModal"
        @close="showSignInModal = false"
      />
    </div>
  </v-container>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import SignInModal from '@/components/Auth/SignInModal.vue';

  export default {
    name: 'FirstScreen',
    metaInfo: {
      title: 'WeCook Menu Ημέρας',
      titleTemplate: '%s',
      htmlAttrs: {
        lang: 'en',
        amp: true
      }
    },
    components: {
      SignInModal
    },
    computed: {
      ...mapGetters([
        "isUserLoggedIn",
        "chefList",
        "allDishes"
      ])
    },
    data() {
      return {
        showSignInModal: false
      };
    },
    methods: {
      ...mapActions([
        "logout",
        "setSignInActiveComponent"
      ]),
      goToMainFeed() {
        this.$router.push({name: 'menu'});
      },
      showLoginForm() {
        this.setSignInActiveComponent('sign-in');
        this.showSignInModal = !this.showSignInModal;
      },
      showRegisterForm() {
        this.setSignInActiveComponent('register');
        this.showSignInModal = !this.showSignInModal;
      },
      becomeAssociatedChef() {
        window.location.href = "mailto:info@wecook.gr";
      }
    }
  };
</script>

<style type="text/css">
  #first-screen {
    position: relative;
    padding: 0 12px;
    background-image: url('~@/assets/landing_desktop_background.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: top right;
    max-height: 74vh;
  }

  #app-bar, #app-bar .v-toolbar__content {
    height: 112px !important;
  }

  #app-bar .v-toolbar__content {
    padding: 4px 0;
  }

  .logo-container {
    max-width: 180px;
  }

  .app-bar-buttons {
    height: inherit;
  }

  #first-screen .login-btn, #first-screen .sign-up-btn {
    border-radius: 15px;
    height: 36px;
  }

  #first-screen .login-btn .v-btn__content, .sign-up-btn .v-btn__content {
    font-size: calc(15px + (22 - 15) * ((100vw - 300px) / (1280 - 300)));
    font-weight: 400;
  }

  #first-screen .login-btn .v-btn__content {
    font-family: inherit;
    color: #001254;
  }

  #first-screen .sign-up-btn .v-btn__content {
    font-family: inherit;
    color: #ffffff;
  }

  .content-wrapper {
    height: calc(100vh - 112px);
    max-height: calc(100vh - 112px);
  }

  .middle-section-container {
    height: calc(70vh - 112px);
  }

  #first-screen .associated-chef-btn .v-btn__content {
    text-decoration: underline;
    font-weight: 700;
  }

  .first-screen-title {
    font-size: 40px;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 24px;
    max-width: 564px;
  }

  .first-screen-subtitle {
    color: black;
    font-size: 30px;
    font-weight: 400;
    line-height: 1;
    margin-bottom: 24px;
    max-width: 564px;
  }

  #first-screen .explore-btn {
    height: 52px;
    padding: 17px 67px;
    background: #001254;
    border-radius: 44px;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: #FFFFFF;
    text-transform: capitalize;
  }

  #first-screen .signin-link {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #001254;
  }

  .left-col, .right-col {
    position: relative;
  }

  #first-screen .features-container {
    max-width: 970px;
    margin: 40px auto 0;
  }

  .features-title {
    font-size: 21px;
    font-weight: 700;
    line-height: 120%;
  }

  .features-icon {
    width: 96px;
    height: 96px;
  }

  @media (max-width: 960px) {
    #first-screen {
      max-height: 500px;
      background-image: url('~@/assets/landing_mobile_background.svg');
      background-size: contain;
      background-position: top right;
    }

    .middle-section-container {
      height: 420px;
    }

    #app-bar, #app-bar .v-toolbar__content {
      height: 40px !important;
    }

    #first-screen .login-btn, #first-screen .sign-up-btn {
      height: 30px;
    }

    .first-screen-title, .first-screen-subtitle {
      max-width: 80%;
    }

    .first-screen-subtitle {
      font-size: 20px;
    }

    .logo-container {
      max-width: 80px;
    }

    .features-title {
      font-size: 11px;
    }

    .features-icon {
      width: 62px;
      height: 62px;
    }

    #first-screen .features-container {
      margin: 24px -12px 0;
    }

    #first-screen .features-container div.col {
      padding: 12px 4px;
    }
  }

  @media (max-width: 600px) {
    .first-screen-title, .first-screen-subtitle {
      max-width: 100%;
    }
  }

  @media (max-width: 400px) {
    .left-col {
      margin-top: 20px;
    }

    #first-screen .features-container {
      margin: 40px auto 0;
    }
  }
</style>